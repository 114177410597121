/* eslint-disable */
// @ts-nocheck
import * as Types from '../../../../../../../declarations/graphql/types';

import { DTOTaskFragment } from './taskFragment.generated';
import { gql } from '@urql/core';
import { TaskFragmentDoc } from './taskFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DTOVerifyTaskMutationVariables = Types.Exact<{
  id: Types.Scalars['Uuid']['input'];
}>;


export type DTOVerifyTaskMutation = { __typename?: 'Mutation', verifyTaskProgress: (
    { __typename?: 'Task' }
    & DTOTaskFragment
  ) };


export const VerifyTaskMutationDocument = gql`
    mutation VerifyTaskMutation($id: Uuid!) {
  verifyTaskProgress(id: $id) {
    ...TaskFragment
  }
}
    ${TaskFragmentDoc}`;

export function useVerifyTaskMutation() {
  return Urql.useMutation<DTOVerifyTaskMutation, DTOVerifyTaskMutationVariables>(VerifyTaskMutationDocument);
};