/* eslint-disable */
// @ts-nocheck
import * as Types from '../../../../../../../declarations/graphql/types';

import { DTODailyTaskFragment } from '../../DailyTasksGroup/useDialyTasks/graphql/dailyTaskFragment.generated';
import { gql } from '@urql/core';
import { DailyTaskFragmentDoc } from '../../DailyTasksGroup/useDialyTasks/graphql/dailyTaskFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DTOStartDailyTaskMutationVariables = Types.Exact<{
  id: Types.Scalars['Uuid']['input'];
}>;


export type DTOStartDailyTaskMutation = { __typename?: 'Mutation', startDailyTaskProgress: (
    { __typename?: 'DailyTask' }
    & DTODailyTaskFragment
  ) };


export const StartDailyTaskMutationDocument = gql`
    mutation StartDailyTaskMutation($id: Uuid!) {
  startDailyTaskProgress(id: $id) {
    ...DailyTaskFragment
  }
}
    ${DailyTaskFragmentDoc}`;

export function useStartDailyTaskMutation() {
  return Urql.useMutation<DTOStartDailyTaskMutation, DTOStartDailyTaskMutationVariables>(StartDailyTaskMutationDocument);
};