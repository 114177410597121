import { CSSProperties, MutableRefObject, ReactNode } from "react";
import BackgroundGrid from "./assets/BackgroundGrid.png";
import BackgroundTopLeftSpot from "./assets/BackgroundTopLeftSpot.svg?react";
import BackgroundBottomRightSpot from "./assets/BackgroundBottomRightSpot.svg?react";
import { cn } from "~/utils";

type Props = {
  children: ReactNode;
  contentWrapperRef?: MutableRefObject<HTMLDivElement | null>;
  wrapperClassName?: string;
  topSpotClassName?: string;
  bottomSpotClassName?: string;
  className?: string;
  style?: CSSProperties;
};

const PageWithBackground = ({
  children,
  className,
  contentWrapperRef,
  style,
  topSpotClassName,
  bottomSpotClassName,
  wrapperClassName
}: Props): JSX.Element => {
  return (
    <div
      className={cn(
        "h-full w-full top-0 bg-no-repeat bg-[length:auto_100%] overflow-hidden bg-fixed bg-[center_top]",
        wrapperClassName
      )}
      style={{ backgroundImage: `url(${BackgroundGrid})` }}
    >
      <BackgroundTopLeftSpot
        className={cn(
          "absolute w-[304px] h-[304px] top-[3px] left-[-64px]",
          topSpotClassName
        )}
      />
      <BackgroundBottomRightSpot
        className={cn(
          "absolute w-[304px] h-[304px] bottom-[-23px] right-[-83px]",
          bottomSpotClassName
        )}
      />
      <div
        className={cn(`relative w-full h-full overflow-y-auto `, className)}
        style={style}
        ref={contentWrapperRef}
      >
        {children}
      </div>
    </div>
  );
};

export default PageWithBackground;
