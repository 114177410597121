import Button from "~/components/ui/button";
import FriendsSection from "../FriendsSection";
import { Trans, useTranslation } from "react-i18next";
import { FriendsLevelRaisingDetail } from "./FriendsLevelRaisingDetails";
import { Drawer, DrawerTrigger } from "~/components/ui/drawer";
import CoinIcon from "~/assets/icons/Coin.png";
import { useBonusesForFriendsByLevel } from "./useBonusesForFriendsByLevel/useBonusesForFriendsByLevel";
import { formatTokens } from "~/components/balance/Tokens/utils";
import { FriendBonuses } from "../FriendsHeader/types";

type Props = {
  firstLevelBonuses: FriendBonuses;
};

export const FriendsLevelRaising = ({ firstLevelBonuses }: Props) => {
  const { t } = useTranslation("referral");
  const { bonuses, maxBonus } = useBonusesForFriendsByLevel(firstLevelBonuses);

  return (
    <Drawer>
      <FriendsSection className="gap-3 h-auto" dividerClassName="hidden">
        <div className="flex flex-col gap-1.5 w-full">
          <div className="text-foreground-tertiary t-title-quaternary h-6 flex items-center font-bold">
            <img src={CoinIcon} className="w-6 h-6 mr-2.5" />
            {maxBonus && (
              <div className="whitespace-pre">
                <Trans
                  i18nKey="referral:levelRaising.title"
                  components={{
                    Highlighted: (
                      <span className="t-title-main font-bold text-foreground-primary" />
                    )
                  }}
                  values={{
                    amount: formatTokens(maxBonus.count)
                  }}
                />
              </div>
            )}
          </div>
          <div className="t-p-quaternary text-foreground-secondary">
            {t("levelRaising.description")}
          </div>
        </div>
        <DrawerTrigger className="outline-none border-none hover:border-none focus:outline-none">
          <Button
            className="overflow-hidden [&>button]:overflow-hidden"
            buttonClassName="px-5"
            size="sm"
            variant="accent"
            fullWidth
          >
            {t("levelRaising.action.moreDetails")}
          </Button>
        </DrawerTrigger>
      </FriendsSection>
      <FriendsLevelRaisingDetail bonuses={bonuses} />
    </Drawer>
  );
};
