/* eslint-disable */
// @ts-nocheck
import * as Types from '../../../../../../../../declarations/graphql/types';

import { gql } from '@urql/core';
export type DTODailyTaskFragment = { __typename?: 'DailyTask', id: any, title: string, description: string, points: number, progress: Types.DTODailyTaskProgress, actionText: string, action: { __typename: 'ClaimFarmingReward', target: number, current: number } | { __typename: 'CompleteDailyTasks', target: number, current: number } | { __typename: 'DailySubscribeToTelegram', link: string } | { __typename: 'UpgradePowerCards', target: number, current: number } };

export const DailyTaskFragmentDoc = gql`
    fragment DailyTaskFragment on DailyTask {
  id
  title
  description
  points
  progress
  actionText
  action {
    ... on CompleteDailyTasks {
      __typename
      target
      current
    }
    ... on ClaimFarmingReward {
      __typename
      target
      current
    }
    ... on UpgradePowerCards {
      __typename
      target
      current
    }
    ... on DailySubscribeToTelegram {
      __typename
      link
    }
  }
}
    `;