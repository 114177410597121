import { ReactNode } from "react";
import { cn } from "~/utils";

type Props = {
  title: string;
  text: ReactNode;
  img?: string;
  titleClassName?: string;
  descriptionClassName?: string;
  imageClassName?: string;
  className?: string;
};

const PageHeader = ({
  title,
  titleClassName = "t-h4 pb-2 text-center",
  text,
  img,
  className,
  descriptionClassName,
  imageClassName
}: Props): JSX.Element => {
  return (
    <div className={cn("flex flex-col", className)}>
      {img && (
        <img
          className={cn("w-full mb-3 h-[72px] object-contain", imageClassName)}
          height={72}
          src={img}
          alt={title}
        />
      )}
      <h1 className={titleClassName}>{title}</h1>
      <p
        className={cn("t-p-quaternary pb-2 text-center", descriptionClassName)}
      >
        {text}
      </p>
    </div>
  );
};

export default PageHeader;
