/* eslint-disable */
// @ts-nocheck
import * as Types from '../../../../../../../declarations/graphql/types';

import { DTOPremiumShopProductFragment } from './premiumShopProductFragment.generated';
import { gql } from '@urql/core';
import { PremiumShopProductFragmentDoc } from './premiumShopProductFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DTOPremiumShopProductsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type DTOPremiumShopProductsQuery = { __typename?: 'Query', products: Array<(
    { __typename?: 'Product' }
    & DTOPremiumShopProductFragment
  )> };


export const PremiumShopProductsDocument = gql`
    query PremiumShopProducts {
  products {
    ...PremiumShopProduct
  }
}
    ${PremiumShopProductFragmentDoc}`;

export function usePremiumShopProductsQuery(options?: Omit<Urql.UseQueryArgs<DTOPremiumShopProductsQueryVariables>, 'query'>) {
  return Urql.useQuery<DTOPremiumShopProductsQuery, DTOPremiumShopProductsQueryVariables>({ query: PremiumShopProductsDocument, ...options });
};