/* eslint-disable */
// @ts-nocheck
import * as Types from '../../../../../declarations/graphql/types';

import { gql } from '@urql/core';
export type DTOSpinRewardFragment = { __typename?: 'FreeSpinSector', id: any, number: number, rewardType: Types.DTORewardType, rewardAmount: number };

export const SpinRewardFragmentDoc = gql`
    fragment SpinRewardFragment on FreeSpinSector {
  id
  number
  rewardType
  rewardAmount
}
    `;