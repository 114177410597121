/* eslint-disable */
// @ts-nocheck
import * as Types from '../../../../../declarations/graphql/types';

import { gql } from '@urql/core';
export type DTOUserFragment = { __typename?: 'Wallet', id: any, address: string, payStatus: Types.DTOPayStatus };

export const UserFragmentDoc = gql`
    fragment UserFragment on Wallet {
  id
  address
  payStatus
}
    `;