import { useTranslation } from "react-i18next";
import FriendsSection from "../FriendsSection";
import FriendsStatsItem from "./FriendsStatsItem";
import CoinIcon from "~/assets/icons/Coin.png";
import Speaker from "~/assets/icons/Speaker.png";

type Props = {
  referees?: number;
  rewards?: number;
  virtualFriends?: number;
};

const FriendsStats = ({
  referees = 0,
  rewards = 0,
  virtualFriends = 0
}: Props): JSX.Element => {
  const { t } = useTranslation("friends");

  return (
    <FriendsSection>
      <FriendsStatsItem
        label={t("stats.rewards")}
        value={rewards}
        icon={<img src={CoinIcon} className="w-8 h-8" />}
      />
      <FriendsStatsItem
        label={t("stats.referrals")}
        value={referees}
        additionalValue={virtualFriends}
        icon={<img src={Speaker} className="w-8 h-8" />}
      />
    </FriendsSection>
  );
};

export default FriendsStats;
