import PageWithBackground from "~/components/layouts/PageWithBackground";

import FriendsBonusDescription from "../FriendsBonusDescription";
import FriendsActions from "../FriendsActions";
import FriendsStats from "../FriendsStats";
import usePagination from "~/utils/graphql/usePagination/usePagination";
import {
  DTORefereesQuery,
  DTORefereesQueryVariables,
  RefereesQueryDocument
} from "../graphql/refereesQuery.generated";
import { Referral } from "../declarations";
import mapDTORefereesToReferrals from "../adapters/mapDTORefereesToReferrals";
import FriendsReferrals from "../FriendsReferrals";
import { useReferralRewardsQuery } from "../graphql/referralRewards.generated";
import { useMeQuery } from "~/graphql/useMe/graphql/meQuery.generated";
import FriendsHeader from "../FriendsHeader";
import { useMemo } from "react";
import { FriendsLevelRaising } from "../FriendsLevelRaising/FriendsLevelRaising";
import usePageTitle from "~/utils/usePageTitle";

export const REFEREES_PER_BATCH = 10;

const Friends = (): JSX.Element => {
  const refereesResult = usePagination<
    DTORefereesQuery,
    "referees",
    DTORefereesQueryVariables,
    Referral
  >({
    query: RefereesQueryDocument,
    queryKey: "referees",
    batchSize: REFEREES_PER_BATCH,
    requestMapper: mapDTORefereesToReferrals
  });
  const [{ data: referralRewards }] = useReferralRewardsQuery();
  const [{ data }] = useMeQuery({});
  usePageTitle();

  const bonuses = useMemo(
    () => ({
      regularAccountBonusForFriend:
        referralRewards?.referralRewards.inviteRegularFriend ?? 0,
      premiumAccountBonusForFriend:
        referralRewards?.referralRewards.invitePremiumFriend ?? 0,
      regularAccountBonusForMe:
        referralRewards?.referralRewards.inviteRegularFriend ?? 0,
      premiumAccountBonusForMe:
        referralRewards?.referralRewards.invitePremiumFriend ?? 0
    }),
    [
      referralRewards?.referralRewards.invitePremiumFriend,
      referralRewards?.referralRewards.inviteRegularFriend
    ]
  );

  return (
    <PageWithBackground className="m-auto mt-3">
      <FriendsHeader bonuses={bonuses} />
      <FriendsBonusDescription bonuses={bonuses} />
      <FriendsLevelRaising firstLevelBonuses={bonuses} />
      <FriendsActions />
      <FriendsStats {...data?.me.referralStats} />
      <FriendsReferrals refereesResult={refereesResult} />
    </PageWithBackground>
  );
};

export default Friends;
