import { UpdateResolver } from "@urql/exchange-graphcache";
import { gql } from "urql";
import { DTOHideUnlockedCardsMutation } from "~/providers/LevelUpProvider/graphql/hideUnlockedCardsMutation.generated";
import { getMyId } from "~/providers/UrqlProvider/cacheExchange/updates/mutations/getMyId";

export const hideUnlockedCards: UpdateResolver = (
  _result: DTOHideUnlockedCardsMutation,
  _args,
  cache
) => {
  const fragment = gql`
    fragment _ on Me {
      id
      levelPopupIsShown
    }
  `;

  const myId = getMyId(cache);

  cache.invalidate("Query", "powerCardsCategories");
  cache.invalidate("Query", "unlockedCards");

  cache.writeFragment(fragment, {
    id: myId,
    levelPopupIsShown: true
  });
};
