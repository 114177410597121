/* eslint-disable */
// @ts-nocheck
import * as Types from '../../../../../../declarations/graphql/types';

import { gql } from '@urql/core';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DTODailyBonusesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type DTODailyBonusesQuery = { __typename?: 'Query', dailyBonuses: Array<{ __typename?: 'DailyBonus', id: any, day: number, amount: number, minReferees: number }> };


export const DailyBonusesQueryDocument = gql`
    query DailyBonusesQuery {
  dailyBonuses {
    id
    day
    amount
    minReferees
  }
}
    `;

export function useDailyBonusesQuery(options?: Omit<Urql.UseQueryArgs<DTODailyBonusesQueryVariables>, 'query'>) {
  return Urql.useQuery<DTODailyBonusesQuery, DTODailyBonusesQueryVariables>({ query: DailyBonusesQueryDocument, ...options });
};