/* eslint-disable */
// @ts-nocheck
import * as Types from '../../../../../../declarations/graphql/types';

import { gql } from '@urql/core';
export type DTOInGameOnboardingFragment = { __typename?: 'InGameOnboarding', id: any, step: Types.DTOInGameOnboardingStep };

export const InGameOnboardingFragmentDoc = gql`
    fragment InGameOnboardingFragment on InGameOnboarding {
  id
  step
}
    `;