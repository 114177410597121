import { TokenCount } from "~/declarations/token";

export enum TaskGroupType {
  dailyTasks = "dailyTasks",
  easyTasks = "easyTasks",
  partners = "partners"
}

export type TaskGroup = {
  type: TaskGroupType;
  nameTranslationKey: string;
};

export enum TaskStatus {
  notStarted = "notStarted",
  checkInProgress = "checkInProgress",
  rewardTook = "rewardTook",
  readyToClaim = "readyToClaim"
}

export type TaskId = string;

export enum TaskType {
  subscribeToInstagram = "subscribeToInstagram",
  subscribeToTelegram = "subscribeToTelegram",
  subscribeToYoutube = "subscribeToYoutube",
  subscribeToX = "subscribeToX",
  watchYoutubeVideo = "watchYoutubeVideo",
  getDailyBonus = "getDailyBonus",
  inviteOneFriends = "inviteOneFriends",
  inviteMoreFriends = "inviteMoreFriends",
  levelUp = "levelUp",
  boostTelegramChannel = "boostTelegramChannel",
  claimFarmingReward = "claimFarmingReward",
  completeDailyTasks = "completeDailyTasks",
  partnerAdvertisement = "partnerAdvertisement",
  upgradePowerCards = "upgradePowerCards",
  connectWallet = "connectWallet",
  sendTransaction = "sendTransaction",
  inGameOnboardingImproveBoost = "inGameOnboardingImproveBoost",
  inGameOnboardingImprovePower = "inGameOnboardingImprovePower"
}

export enum TaskComplexity {
  easy = "easy"
}

export type Task = {
  id: TaskId;
  type: TaskType;
  name: string;
  group: TaskGroupType;
  description?: string;
  reward: TokenCount | null;
  action: TaskAction;
  status: TaskStatus;
  complexity: TaskComplexity;
};

export enum TaskActionType {
  openLink = "openLink",
  openDialog = "openDialog",
  checkStatusInPlace = "checkStatusInPlace",
  openDailogAfterSubtasksCompleted = "openDailogAfterSubtasksCompleted"
}

export enum TaskDialogType {
  watchYoutubeVideo = "watchYoutubeVideo",
  Join = "Join"
}

export type TaskAction =
  | TaskOpenLinkAction
  | TaskOpenDialogAction
  | TaskCheckStatusInPlaceAction
  | TaskOpenDailogAfterSubtasksCompletedAction;

export type TaskActionBase = {
  isInProgress: boolean;
  actionText?: string;
  shouldNotForceActionBeforeCheck?: boolean;
  shouldCloseAfterCheck?: boolean;
  shouldBeCheckedImmediatelyAfterStart?: boolean;
  shouldNotBeChecked?: boolean;
  shouldNotShowActionButton?: boolean;
};

export type TaskOpenLinkAction = TaskActionBase & {
  type: TaskActionType.openLink;
  link: TaskActionLink;
};

export type TaskOpenDialogAction = TaskActionBase & {
  type: TaskActionType.openDialog;
  dialog: TaskDialogType;
  link?: TaskActionLink;
};

export type TaskCheckStatusInPlaceAction = TaskActionBase & {
  type: TaskActionType.checkStatusInPlace;
  link: TaskActionLink;
};

export type TaskOpenDailogAfterSubtasksCompletedAction = TaskActionBase & {
  type: TaskActionType.openDailogAfterSubtasksCompleted;
  dialog: TaskDialogType.Join;
  subtaskCount?: {
    completed: number;
    total: number;
  };
  link?: TaskActionLink;
};

export type TaskActionLink = {
  url: string;
  search?: Record<string, string>;
  isTelegramLink: boolean;
  isExternal: boolean;
};

export type TaskActionHandler = (task: Task) => void;
export type TaskCheckHandler = (task: Task) => Promise<void>;
export type TaskDialogOpenHandler = (task: Task) => void;

export enum TaskFilter {
  daily = "daily",
  easy = "easy",
  partners = "partners"
}

export type TaskFilterDescription = {
  value: TaskFilter;
  translationKey: string;
  isEnabled: boolean;
};
