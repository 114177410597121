/* eslint-disable */
// @ts-nocheck
import * as Types from '../../../../declarations/graphql/types';

import { DTOCurrentFarmingTaskFragment } from '../../../components/farming/useFarming/graphql/currentFarmingTaskFragment.generated';
import { DTOCurrentModelFragment } from './meCurrentModelFragment.generated';
import { DTORobotInfoFragment } from '../../robotInfoFragment.generated';
import { DTORobotLevelFragment } from '../../robotLevelFragment.generated';
import { DTODailyStreakFragment } from './dailyStreakFragment.generated';
import { DTOInGameOnboardingFragment } from '../../../components/onboarding/internal/InternalOnboardingProvider/graphql/inGameOnboardingFragment.generated';
import { gql } from '@urql/core';
import { CurrentFarmingTaskFragmentDoc } from '../../../components/farming/useFarming/graphql/currentFarmingTaskFragment.generated';
import { CurrentModelFragmentDoc } from './meCurrentModelFragment.generated';
import { RobotInfoFragmentDoc } from '../../robotInfoFragment.generated';
import { RobotLevelFragmentDoc } from '../../robotLevelFragment.generated';
import { DailyStreakFragmentDoc } from './dailyStreakFragment.generated';
import { InGameOnboardingFragmentDoc } from '../../../components/onboarding/internal/InternalOnboardingProvider/graphql/inGameOnboardingFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DTOMeQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type DTOMeQuery = { __typename?: 'Query', me: { __typename?: 'Me', id: any, balance: number, power: number, onboarded: boolean, profile: { __typename?: 'Profile', id: any, nickname: string }, currentFarmingTask?: (
      { __typename?: 'FarmingTask' }
      & DTOCurrentFarmingTaskFragment
    ) | null, currentModel: (
      { __typename?: 'ModelLevel' }
      & DTOCurrentModelFragment
    ), robotInfo: (
      { __typename?: 'RobotInfo' }
      & DTORobotInfoFragment
    ), referrer: { __typename?: 'Referrer', refereeStatus: Types.DTORefereeStatus, refereePoints: number }, referralStats: { __typename?: 'ReferralStats', referees: number, rewards: number, virtualFriends: number }, dailyStreak: (
      { __typename?: 'DailyStreak' }
      & DTODailyStreakFragment
    ), inGameOnboarding: (
      { __typename?: 'InGameOnboarding' }
      & DTOInGameOnboardingFragment
    ) } };


export const MeQueryDocument = gql`
    query MeQuery {
  me {
    id
    profile {
      id
      nickname
    }
    balance
    power
    onboarded
    currentFarmingTask {
      ...CurrentFarmingTaskFragment
    }
    currentModel {
      ...CurrentModelFragment
    }
    robotInfo {
      ...RobotInfoFragment
    }
    referrer {
      refereeStatus
      refereePoints
    }
    referralStats {
      referees
      rewards
      virtualFriends
    }
    dailyStreak {
      ...DailyStreakFragment
    }
    inGameOnboarding {
      ...InGameOnboardingFragment
    }
  }
}
    ${CurrentFarmingTaskFragmentDoc}
${CurrentModelFragmentDoc}
${RobotInfoFragmentDoc}
${RobotLevelFragmentDoc}
${DailyStreakFragmentDoc}
${InGameOnboardingFragmentDoc}`;

export function useMeQuery(options?: Omit<Urql.UseQueryArgs<DTOMeQueryVariables>, 'query'>) {
  return Urql.useQuery<DTOMeQuery, DTOMeQueryVariables>({ query: MeQueryDocument, ...options });
};