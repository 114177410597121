/* eslint-disable */
// @ts-nocheck
import * as Types from '../../declarations/graphql/types';

import { gql } from '@urql/core';
export type DTOPowerCardFragment = { __typename?: 'PowerCard', id: any, title: string, description: string, imageUrl: string, maxLevel: number };

export const PowerCardFragmentDoc = gql`
    fragment PowerCardFragment on PowerCard {
  id
  title
  description
  imageUrl
  maxLevel
}
    `;