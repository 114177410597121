/* eslint-disable */
// @ts-nocheck
import * as Types from '../../../../../../../declarations/graphql/types';

import { DTODailyTaskFragment } from '../../DailyTasksGroup/useDialyTasks/graphql/dailyTaskFragment.generated';
import { gql } from '@urql/core';
import { DailyTaskFragmentDoc } from '../../DailyTasksGroup/useDialyTasks/graphql/dailyTaskFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DTOVerifyDailyTaskMutationVariables = Types.Exact<{
  id: Types.Scalars['Uuid']['input'];
}>;


export type DTOVerifyDailyTaskMutation = { __typename?: 'Mutation', verifyDailyTaskProgress: (
    { __typename?: 'DailyTask' }
    & DTODailyTaskFragment
  ) };


export const VerifyDailyTaskMutationDocument = gql`
    mutation VerifyDailyTaskMutation($id: Uuid!) {
  verifyDailyTaskProgress(id: $id) {
    ...DailyTaskFragment
  }
}
    ${DailyTaskFragmentDoc}`;

export function useVerifyDailyTaskMutation() {
  return Urql.useMutation<DTOVerifyDailyTaskMutation, DTOVerifyDailyTaskMutationVariables>(VerifyDailyTaskMutationDocument);
};