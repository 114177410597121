/* eslint-disable */
// @ts-nocheck
import * as Types from '../../../../../../../declarations/graphql/types';

import { gql } from '@urql/core';
export type DTOPremiumShopProductFragment = { __typename: 'Product', id: any, category: Types.DTOProductCategory, modifier: number, price: number, title: string, description: string, coverImage: string };

export const PremiumShopProductFragmentDoc = gql`
    fragment PremiumShopProduct on Product {
  __typename
  id
  category
  modifier
  price
  title
  description
  coverImage
}
    `;