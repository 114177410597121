import { startFarming } from "./startFarming";
import { boostModel } from "./boostModel";
import { claimRefereeReward } from "./claimRefereeReward";
import { claimFarmingReward } from "./claimFarmingReward";
import { hideUnlockedCards } from "./hideUnlockedCards";

const mutationsIndex = {
  startFarming,
  boostModel,
  claimRefereeReward,
  hideUnlockedCards,
  claimFarmingReward
};

export default mutationsIndex;
