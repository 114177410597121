/* eslint-disable */
// @ts-nocheck
import * as Types from '../../../../../../../../declarations/graphql/types';

import { DTODailyTaskFragment } from './dailyTaskFragment.generated';
import { gql } from '@urql/core';
import { DailyTaskFragmentDoc } from './dailyTaskFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DTODailyTasksQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type DTODailyTasksQuery = { __typename?: 'Query', dailyTasks: Array<(
    { __typename?: 'DailyTask' }
    & DTODailyTaskFragment
  )> };


export const DailyTasksQueryDocument = gql`
    query DailyTasksQuery {
  dailyTasks {
    ...DailyTaskFragment
  }
}
    ${DailyTaskFragmentDoc}`;

export function useDailyTasksQuery(options?: Omit<Urql.UseQueryArgs<DTODailyTasksQueryVariables>, 'query'>) {
  return Urql.useQuery<DTODailyTasksQuery, DTODailyTasksQueryVariables>({ query: DailyTasksQueryDocument, ...options });
};