import { miniAppReady, backButton } from "@telegram-apps/sdk-react";
import { useEffect } from "react";
import { handleException } from "~/components/errors/helpers";

export const useReady = () => {
  useEffect(() => {
    try {
      miniAppReady();

      if (backButton.isSupported()) {
        backButton.hide();
      }
    } catch (error) {
      handleException(error);
    }
  }, []);
};
