/* eslint-disable */
// @ts-nocheck
import * as Types from '../../../../../declarations/graphql/types';

import { DTOPowerCardFragment } from '../../../../graphql/powerCardFragment.generated';
import { gql } from '@urql/core';
import { PowerCardFragmentDoc } from '../../../../graphql/powerCardFragment.generated';
export type DTOPowerCardProposalFragment = { __typename?: 'PowerCardProposal', id: any, userLevel: number, minRobotLevel: number, sellingPrice: number, sellingLevel: number, sellingPowerBoost: number, powerCard?: (
    { __typename?: 'PowerCard' }
    & DTOPowerCardFragment
  ) | null };

export const PowerCardProposalFragmentDoc = gql`
    fragment PowerCardProposalFragment on PowerCardProposal {
  id
  powerCard {
    ...PowerCardFragment
  }
  userLevel
  minRobotLevel
  sellingPrice
  sellingLevel
  sellingPowerBoost
}
    `;