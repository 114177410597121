import "./components/errors/initSentry.ts";
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "../i18n/config.ts";
import { TelegramProvider } from "~/providers/TelegramProvider/TelegramProvider.tsx";
import { UrqlProvider } from "~/providers/UrqlProvider/UrqlProvider";
import App from "~/App.tsx";
import { ErrorBoundary } from "~/components/errors/ErrorBoundary/ErrorBoundary.tsx";

import ReactGA from "react-ga4";
import { backButton, swipeBehavior, init } from "@telegram-apps/sdk-react";

const GA_MEASUREMENT_ID = import.meta.env.VITE_GA_ID;

if (GA_MEASUREMENT_ID) {
  ReactGA.initialize(GA_MEASUREMENT_ID);
}

init();

if (backButton.isSupported()) {
  backButton.mount();
}

if (swipeBehavior.isSupported()) {
  swipeBehavior.mount();
}

const pathname = window.location.pathname;

if (pathname !== "/") {
  window.location.href = "/";
} else {
  ReactDOM.createRoot(document.getElementById("root")!).render(
    <React.StrictMode>
      <ErrorBoundary>
        <UrqlProvider>
          <TelegramProvider>
            <App />
          </TelegramProvider>
        </UrqlProvider>
      </ErrorBoundary>
    </React.StrictMode>
  );
}
