/* eslint-disable */
// @ts-nocheck
import * as Types from '../../../../../declarations/graphql/types';

import { DTOPowerCardProposalFragment } from './powerCardProposalFragment.generated';
import { DTOPowerCardFragment } from '../../../../graphql/powerCardFragment.generated';
import { gql } from '@urql/core';
import { PowerCardProposalFragmentDoc } from './powerCardProposalFragment.generated';
import { PowerCardFragmentDoc } from '../../../../graphql/powerCardFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DTOPowerCardCategoriesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type DTOPowerCardCategoriesQuery = { __typename?: 'Query', powerCardsCategories: Array<{ __typename?: 'Category', id: Types.DTOPowerCardCategory, name: string, minRobotLevel: number, cardsProposals: Array<(
      { __typename?: 'PowerCardProposal' }
      & DTOPowerCardProposalFragment
    )> }> };


export const PowerCardCategoriesQueryDocument = gql`
    query PowerCardCategoriesQuery {
  powerCardsCategories {
    id
    name
    minRobotLevel
    cardsProposals {
      ...PowerCardProposalFragment
    }
  }
}
    ${PowerCardProposalFragmentDoc}
${PowerCardFragmentDoc}`;

export function usePowerCardCategoriesQuery(options?: Omit<Urql.UseQueryArgs<DTOPowerCardCategoriesQueryVariables>, 'query'>) {
  return Urql.useQuery<DTOPowerCardCategoriesQuery, DTOPowerCardCategoriesQueryVariables>({ query: PowerCardCategoriesQueryDocument, ...options });
};