/* eslint-disable */
// @ts-nocheck
import * as Types from '../../declarations/graphql/types';

import { DTORobotLevelFragment } from './robotLevelFragment.generated';
import { gql } from '@urql/core';
import { RobotLevelFragmentDoc } from './robotLevelFragment.generated';
export type DTORobotInfoFragment = { __typename?: 'RobotInfo', id: any, progress: number, currentLevel: (
    { __typename?: 'RobotLevel', name: string }
    & DTORobotLevelFragment
  ), nextLevel?: (
    { __typename?: 'RobotLevel' }
    & DTORobotLevelFragment
  ) | null };

export const RobotInfoFragmentDoc = gql`
    fragment RobotInfoFragment on RobotInfo {
  id
  progress
  currentLevel {
    ...RobotLevelFragment
    name
  }
  nextLevel {
    ...RobotLevelFragment
  }
}
    `;