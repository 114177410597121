import { cva } from "class-variance-authority";
import { cn } from "~/utils";

type Props = {
  percent: number;
  size?: "sm" | "md";
  className?: string;
};

const wrapperVariants = cva(
  "relative overflow-hidden bg-background-primary/[0.04] backdrop-blur-md",
  {
    variants: {
      size: {
        md: "rounded-[20px]",
        sm: "rounded-[8px]"
      }
    }
  }
);

const barVariants = cva(
  "bg-gradient-loader animate-loader-go transition-transform",
  {
    variants: {
      size: {
        md: "h-12 bg-[length:48px_48px]",
        sm: "h-8 bg-[length:32px_32px]"
      }
    }
  }
);

const percentValueVariants = cva("absolute right-4", {
  variants: {
    size: {
      md: "top-1.5 t-h2",
      sm: "top-[3px] t-h4"
    }
  }
});

const percentSignVariants = cva("text-foreground-tertiary", {
  variants: {
    size: {
      md: "t-h6",
      sm: "t-h6"
    }
  }
});

export const ProgressBar = ({ percent, size = "md", className }: Props) => (
  <div className={cn(wrapperVariants({ size }), className)}>
    <div
      className={cn(barVariants({ size }))}
      style={{ transform: `translate(${percent - 100}%)` }}
    />
    <span className={cn(percentValueVariants({ size }))}>
      {percent}
      <span className={cn(percentSignVariants({ size }))}>%</span>
    </span>
  </div>
);
