export enum Platform {
  ios = "ios",
  android = "android",
  desktop = "desktop"
}

export const getPlatform = (): Platform => {
  switch (true) {
    case /iPhone|iPad/i.test(window.navigator.userAgent):
      return Platform.ios;
    case /Android/i.test(window.navigator.userAgent):
      return Platform.android;
    default:
      return Platform.desktop;
  }

  return Platform.desktop;
};

export const isAndroidOrIos = (): boolean => {
  return [Platform.ios, Platform.android].includes(getPlatform());
};

export const isIOS = (): boolean => {
  return Platform.ios === getPlatform();
};
