import { useTranslation } from "react-i18next";
import { Loader } from "~/components/ui/loaders/Loader";
import SplashScreen from "./assets/SplashScreen.jpeg";

type Props = {
  isDone: boolean;
  className?: string;
};

export const InitialAppLoader = ({ isDone, className }: Props) => {
  const { t } = useTranslation("common");

  return (
    <Loader isDone={isDone} className={className}>
      <img
        src={SplashScreen}
        className="absolute top-0 left-0 h-full portrait:h-full landscape:h-auto w-full object-cover"
      />
      <div className="fixed left-1/2 -translate-x-1/2 bottom-[84px] flex z-10 flex-col gap-1 py-3 px-8 text-center mt-auto">
        <h3 className="t-h3">{t("appNameShort")}</h3>
        <div className="t-p-quaternary text-foreground-secondary whitespace-nowrap">
          {t("loading.text")}
        </div>
      </div>
    </Loader>
  );
};
