/* eslint-disable */
// @ts-nocheck
import * as Types from '../../../../declarations/graphql/types';

import { DTORobotLevelFragment } from '../../../graphql/robotLevelFragment.generated';
import { DTOPageInfoFragment } from '../../../graphql/pageInfoFragment.generated';
import { gql } from '@urql/core';
import { RobotLevelFragmentDoc } from '../../../graphql/robotLevelFragment.generated';
import { PageInfoFragmentDoc } from '../../../graphql/pageInfoFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DTORefereesQueryVariables = Types.Exact<{
  first: Types.Scalars['Int']['input'];
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type DTORefereesQuery = { __typename?: 'Query', referees: { __typename?: 'Referees', edges: Array<{ __typename?: 'RefereeEdge', cursor: string, node: { __typename?: 'Referee', id: any, points: number, invitedAt: any, profile?: { __typename?: 'Profile', id: any, nickname: string, level: number, robotLevel: (
            { __typename?: 'RobotLevel' }
            & DTORobotLevelFragment
          ) } | null } }>, pageInfo: (
      { __typename?: 'PageInfo' }
      & DTOPageInfoFragment
    ) } };


export const RefereesQueryDocument = gql`
    query RefereesQuery($first: Int!, $after: String) {
  referees(after: $after, first: $first) {
    edges {
      cursor
      node {
        id
        points
        profile {
          id
          nickname
          level
          robotLevel {
            ...RobotLevelFragment
          }
        }
        invitedAt
      }
    }
    pageInfo {
      ...PageInfoFragment
    }
  }
}
    ${RobotLevelFragmentDoc}
${PageInfoFragmentDoc}`;

export function useRefereesQuery(options: Omit<Urql.UseQueryArgs<DTORefereesQueryVariables>, 'query'>) {
  return Urql.useQuery<DTORefereesQuery, DTORefereesQueryVariables>({ query: RefereesQueryDocument, ...options });
};