import { cn } from "~/utils";
import styles from "./styles.module.css";

type Props = {
  top: number;
  left: number;
  animationDelay: number;
  animate?: boolean;
  opacity?: number;
};

const LiftingBlink = ({
  top,
  left,
  opacity,
  animate = true,
  animationDelay
}: Props): JSX.Element => {
  return (
    <div
      style={{
        animationPlayState: animate ? "running" : "paused",
        top: `${top}%`,
        left: `${left}%`,
        animationDelay: `${animationDelay}ms`,
        opacity: opacity
      }}
      className={cn(
        "absolute rounded-full bg-white w-[0.5px] h-[0.5px]",
        styles["lifting-blink--animation"]
      )}
    />
  );
};

export default LiftingBlink;
