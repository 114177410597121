import { FC } from "react";
import { DTOPowerCardCategoriesQuery } from "./graphql/powerCardCategoriesQuery.generated";

export enum PowerKnownCategory {
  hardware = "hardware",
  data = "data",
  team = "team",
  partnership = "partnership"
}

export enum PowerAvailabilityVariant {
  available = "available",
  notEnoughTokens = "notEnoughTokens",
  notAvailableForCurrentLevel = "notAvailableForCurrentLevel",
  maxLevel = "maxLevel"
}

export type PowerAvailability =
  | {
      type:
        | PowerAvailabilityVariant.available
        | PowerAvailabilityVariant.notEnoughTokens
        | PowerAvailabilityVariant.maxLevel;
    }
  | {
      type: PowerAvailabilityVariant.notAvailableForCurrentLevel;
      requiredLevel: number;
    };

export type PowerCategory = {
  id: PowerKnownCategory;
  labelTranslationKey: string;
  availableAtLevel: number;
  available: boolean;
  Icon: FC<React.SVGProps<SVGSVGElement>>;
};

export type DTOPowerCategoryWithCards =
  DTOPowerCardCategoriesQuery["powerCardsCategories"][0];

export type DTOPowerCategory = Omit<
  DTOPowerCategoryWithCards,
  "cardsProposals"
>;

export type DTOPowerCard =
  DTOPowerCardCategoriesQuery["powerCardsCategories"][0]["cardsProposals"][0];

export type PowerCardsByCategories = Record<
  PowerKnownCategory,
  PowerCardModel[]
>;

export type PowerCardBaseModel = {
  id: string;
  image: string;
  name: string;
  power: number;
};

export type PowerCardModel = PowerCardBaseModel & {
  availability: PowerAvailability;
  description: string;
  level: number;
  levelToPurchase: number;
  price: number;
};
