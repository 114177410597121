import { useSignal, viewport, swipeBehavior } from "@telegram-apps/sdk-react";
import { useState } from "react";

export const useExpandAndLock = () => {
  const [isInitialized, setIsInitialized] = useState(false);
  const isExpanded = useSignal(viewport.isExpanded);

  if (!isInitialized && !isExpanded) {
    try {
      viewport.expand();
      swipeBehavior.disableVertical();
    } catch (error) {
      console.error(error);
    } finally {
      setIsInitialized(true);
    }
  }
};
