import {
  TaskComplexity,
  TaskGroup,
  TaskGroupType,
  TaskType
} from "~/components/rewards/Rewards/Tasks/types";
import {
  TaskGroupCondition,
  TaskMutations
} from "~/components/rewards/Rewards/Tasks/useTasks/types";
import { MILLISECONDS_IN_SECOND } from "~/constants/time";
import {
  DTOStartDailyTaskMutation,
  StartDailyTaskMutationDocument
} from "./graphql/startDailyTaskMutation.generated";
import {
  DTOStartTaskMutation,
  StartTaskMutationDocument
} from "./graphql/startTaskMutation.generated";
import {
  DTOVerifyDailyTaskMutation,
  VerifyDailyTaskMutationDocument
} from "./graphql/verifyDailyTaskMutation.generated";
import {
  DTOVerifyTaskMutation,
  VerifyTaskMutationDocument
} from "./graphql/verifyTaskMutation.generated";

export const TASK_GROUP_CONDITIONS: Readonly<
  [TaskGroupType, TaskGroupCondition][]
> = [
  [TaskGroupType.easyTasks, task => task.complexity === TaskComplexity.easy]
];

export const TASK_GROUP_ORDER: Readonly<TaskGroupType[]> = [
  TaskGroupType.dailyTasks,
  TaskGroupType.easyTasks,
  TaskGroupType.partners
];

export const TASK_GROUP_QUERY_PARAMETER = "taskFilter";

export const TASK_GROUPS_BY_TYPE: Readonly<Record<TaskGroupType, TaskGroup>> = {
  [TaskGroupType.dailyTasks]: {
    type: TaskGroupType.dailyTasks,
    nameTranslationKey: "rewards:tasks.group.dailyTasks"
  },
  [TaskGroupType.easyTasks]: {
    type: TaskGroupType.easyTasks,
    nameTranslationKey: "rewards:tasks.group.easyTasks"
  },
  [TaskGroupType.partners]: {
    type: TaskGroupType.partners,
    nameTranslationKey: "rewards:tasks.group.partners"
  }
};

export const FAKE_TASK_CHECK_TIMEOUT = 3 * MILLISECONDS_IN_SECOND;

export const COMPLETED_TASKS_THAT_SHOULD_NOT_BE_HIDDEN: Readonly<
  Set<TaskType>
> = new Set([TaskType.completeDailyTasks]);

export const MUTATIONS_BY_TASK_GROUP: Partial<
  Record<TaskGroupType, TaskMutations>
> = {
  [TaskGroupType.dailyTasks]: {
    start: async (client, id) => {
      const response = await client
        .mutation<DTOStartDailyTaskMutation>(StartDailyTaskMutationDocument, {
          id
        })
        .toPromise();

      return {
        error: response.error,
        data: {
          progress: response.data?.startDailyTaskProgress.progress
        }
      };
    },
    verify: async (client, id) => {
      const response = await client
        .mutation<DTOVerifyDailyTaskMutation>(VerifyDailyTaskMutationDocument, {
          id
        })
        .toPromise();

      return {
        error: response.error,
        data: {
          progress: response.data?.verifyDailyTaskProgress.progress
        }
      };
    }
  },
  [TaskGroupType.easyTasks]: {
    start: async (client, id) => {
      const response = await client
        .mutation<DTOStartTaskMutation>(StartTaskMutationDocument, {
          id
        })
        .toPromise();

      return {
        error: response.error,
        data: {
          progress: response.data?.startTaskProgress.progress
        }
      };
    },
    verify: async (client, id) => {
      const response = await client
        .mutation<DTOVerifyTaskMutation>(VerifyTaskMutationDocument, {
          id
        })
        .toPromise();

      return {
        error: response.error,
        data: {
          progress: response.data?.verifyTaskProgress.progress
        }
      };
    }
  }
};
