import CoinIcon from "~/assets/icons/Coin.png";
import { formatTokens } from "~/components/balance/Tokens/utils";

type Props = {
  amount: number;
  description: string;
};

const FriendsBonusDescriptionItem = ({
  amount,
  description
}: Props): JSX.Element => {
  return (
    <div className="flex flex-col gap-[6px] w-full overflow-hidden">
      <div className="flex gap-[10px]">
        <img src={CoinIcon} className="w-6 h-6" />
        <span className="t-title-main font-bold font-regular">
          +{formatTokens(amount, { numberToShortenFrom: 1e3 })}
        </span>
      </div>
      <span className="block t-p-quaternary max-[380px]:text-[0.7rem] text-foreground-secondary text-ellipsis overflow-hidden whitespace-nowrap">
        {description}
      </span>
    </div>
  );
};

export default FriendsBonusDescriptionItem;
