import { formatTokens } from "~/components/balance/Tokens/utils";
import { TokenCount } from "~/declarations/token";
import { FormatTokensFunctionOptions } from "~/components/balance/Tokens/types";
import { forwardRef } from "react";
import { Currency } from "../constants";
import { IMAGE_BY_CURRENCY } from "./constants";

type Props = {
  count: TokenCount;
  coinClassName?: string;
  className?: string;
  nameClassName?: string;
  textClassName?: string;
  currency?: Currency;
  withCoin?: boolean;
  formatOptions?: FormatTokensFunctionOptions;
};

export const Tokens = forwardRef<HTMLSpanElement, Props>(
  (
    {
      count,
      coinClassName = "w-8 h-8 mr-2.5",
      className = "flex items-center",
      textClassName = "t-h3 font-regular",
      currency = Currency.nativeCoin,
      withCoin,
      formatOptions
    }: Props,
    ref
  ) => {
    const coinImage = IMAGE_BY_CURRENCY[currency];

    return (
      <span ref={ref} className={className}>
        {withCoin && (
          <span className={coinClassName}>
            <img className="w-full h-full" src={coinImage} />
          </span>
        )}
        <span className={textClassName}>
          {formatTokens(count, formatOptions)}
        </span>
      </span>
    );
  }
);

Tokens.displayName = "Tokens";
