import { ReactNode } from "react";
import { formatTokens } from "~/components/balance/Tokens/utils";
import { NumberSign } from "~/constants/signs";

type Props = {
  label: string;
  value: number;
  additionalValue?: number;
  icon: ReactNode;
};

const FriendsStatsItem = ({
  label,
  value,
  additionalValue,
  icon
}: Props): JSX.Element => {
  return (
    <div className="flex items-center w-full gap-[10px]">
      {icon}
      <div className="flex flex-col gap-[0.5]">
        <span className="t-p-quaternary text-foreground-secondary">
          {label}
        </span>
        <span className="t-h6 leading-[22px] font-regular">
          {formatTokens(value)}{" "}
          {additionalValue && additionalValue > 0
            ? `(${formatTokens(additionalValue, { prefix: NumberSign.plus })})`
            : null}
        </span>
      </div>
    </div>
  );
};

export default FriendsStatsItem;
