import { UpdateResolver } from "@urql/exchange-graphcache";
import { gql } from "urql";
import { DTOClaimRefereeRewardMutation } from "~/graphql/claimRefereeRewardMutation.generated";

import { getMyId } from "~/providers/UrqlProvider/cacheExchange/updates/mutations/getMyId";

export const claimRefereeReward: UpdateResolver = (
  result: DTOClaimRefereeRewardMutation,
  _args,
  cache
) => {
  const fragment = gql`
    fragment _ on Me {
      id
      referrer {
        __typename
        refereeStatus
      }
    }
  `;

  const id = getMyId(cache);

  cache.writeFragment(fragment, {
    id,
    referrer: {
      __typename: "Referrer",
      refereeStatus: result.claimRefereeReward.refereeStatus
    }
  });
};
