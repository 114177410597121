import { Trans } from "react-i18next";
import { formatTokens } from "~/components/balance/Tokens/utils";
import { FriendBonuses } from "~/components/friends/FriendsHeader/types";

type Props = {
  translationKey: string;
  translationValues: FriendBonuses;
};

const FriendsHeaderHowItWorksStatement = ({
  translationKey,
  translationValues
}: Props): JSX.Element => {
  return (
    <div className="t-p-tertiary text-foreground-secondary">
      <Trans
        i18nKey={translationKey}
        values={{
          regularAccountBonusForFriend: formatTokens(
            translationValues.regularAccountBonusForFriend
          ),
          premiumAccountBonusForFriend: formatTokens(
            translationValues.premiumAccountBonusForFriend
          ),
          regularAccountBonusForMe: formatTokens(
            translationValues.regularAccountBonusForMe
          ),
          premiumAccountBonusForMe: formatTokens(
            translationValues.premiumAccountBonusForMe
          )
        }}
        ns="friends"
        components={{
          Def: <span className="t-title-tertiary text-foreground" />
        }}
      />
    </div>
  );
};

export default FriendsHeaderHowItWorksStatement;
