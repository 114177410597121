import { cn } from "~/utils";
import ButtonPrimaryBlink from "./LiftingBlink";

const BLINKS = [
  {
    opacity: 0.48,
    top: 91,
    left: 77,
    animationDelay: 0
  },
  { opacity: 0.16, top: 78, left: 1, animationDelay: 100 },
  { opacity: 0.16, top: 88, left: 2, animationDelay: 1000 },
  { opacity: 0.16, top: 92, left: 4, animationDelay: 5000 },
  { opacity: 0.48, top: 90, left: 6, animationDelay: 350 },
  { opacity: 0.48, top: 80, left: 5, animationDelay: 2350 },
  { opacity: 0.48, top: 92, left: 8, animationDelay: 3050 },
  { opacity: 0.48, top: 90, left: 10, animationDelay: 850 },
  { opacity: 0.24, top: 72, left: 12, animationDelay: 6000 },
  { opacity: 0.24, top: 80, left: 14, animationDelay: 2600 },
  { opacity: 0.16, top: 74, left: 16, animationDelay: 0 },
  { opacity: 0.16, top: 82, left: 18, animationDelay: 8000 },
  { opacity: 0.16, top: 73, left: 20, animationDelay: 1800 },
  { opacity: 0.16, top: 96, left: 22, animationDelay: 990 },
  { opacity: 0.16, top: 80, left: 24, animationDelay: 5 },
  { opacity: 0.16, top: 90, left: 26, animationDelay: 2050 },
  { opacity: 0.16, top: 85, left: 28, animationDelay: 5600 },
  { opacity: 0.24, top: 82, left: 30, animationDelay: 1600 },
  { opacity: 0.24, top: 100, left: 32, animationDelay: 5700 },
  { opacity: 0.24, top: 90, left: 34, animationDelay: 2700 },
  { opacity: 0.16, top: 84, left: 36, animationDelay: 4180 },
  { opacity: 0.16, top: 92, left: 38, animationDelay: 1800 },
  { opacity: 0.24, top: 78, left: 40, animationDelay: 5205 },
  { opacity: 0.24, top: 85, left: 42, animationDelay: 3520 },
  { opacity: 0.24, top: 99, left: 44, animationDelay: 3090 },
  { opacity: 0.24, top: 98, left: 46, animationDelay: 3000 },
  { opacity: 0.24, top: 88, left: 49, animationDelay: 900 },
  { opacity: 0.24, top: 78, left: 48, animationDelay: 6900 },
  { opacity: 0.24, top: 99, left: 51, animationDelay: 1690 },
  { opacity: 0.24, top: 79, left: 50, animationDelay: 4690 },
  { opacity: 0.24, top: 95, left: 52, animationDelay: 0 },
  { opacity: 0.24, top: 94, left: 53, animationDelay: 200 },
  { opacity: 0.24, top: 84, left: 54, animationDelay: 3000 },
  { opacity: 0.16, top: 99, left: 56, animationDelay: 7240 },
  { opacity: 0.16, top: 82, left: 57, animationDelay: 1240 },
  { opacity: 0.16, top: 93, left: 58, animationDelay: 2400 },
  { opacity: 0.48, top: 85, left: 60, animationDelay: 7700 },
  { opacity: 0.48, top: 93, left: 62, animationDelay: 1770 },
  { opacity: 0.16, top: 72, left: 64, animationDelay: 5700 },
  { opacity: 0.16, top: 83, left: 96, animationDelay: 2570 },
  { opacity: 0.48, top: 81, left: 68, animationDelay: 9000 },
  { opacity: 0.48, top: 80, left: 70, animationDelay: 1090 },
  { opacity: 0.16, top: 77, left: 72, animationDelay: 0 },
  { opacity: 0.16, top: 86, left: 74, animationDelay: 3800 },
  { opacity: 0.16, top: 66, left: 76, animationDelay: 5820 },
  { opacity: 0.16, top: 65, left: 78, animationDelay: 2820 },
  { opacity: 0.16, top: 91, left: 80, animationDelay: 6140 },
  { opacity: 0.16, top: 91, left: 81, animationDelay: 140 },
  { opacity: 0.16, top: 72, left: 82, animationDelay: 1400 },
  { opacity: 0.48, top: 89, left: 84, animationDelay: 4870 },
  { opacity: 0.48, top: 89, left: 83, animationDelay: 870 },
  { opacity: 0.48, top: 87, left: 86, animationDelay: 3870 },
  { opacity: 0.16, top: 56, left: 88, animationDelay: 7170 },
  { opacity: 0.16, top: 86, left: 90, animationDelay: 1700 },
  { opacity: 0.16, top: 86, left: 91, animationDelay: 70 },
  { opacity: 0.48, top: 95, left: 92, animationDelay: 3600 },
  { opacity: 0.48, top: 99, left: 94, animationDelay: 5600 },
  { opacity: 0.48, top: 99, left: 95, animationDelay: 1600 },
  { opacity: 0.48, top: 66, left: 96, animationDelay: 0 },
  { opacity: 0.48, top: 76, left: 97, animationDelay: 4000 },
  { opacity: 0.48, top: 85, left: 98, animationDelay: 2800 },
  { opacity: 0.48, top: 95, left: 99, animationDelay: 6800 },
  { opacity: 0.48, top: 97, left: 99, animationDelay: 3800 }
];

type Props = {
  className?: string;
};

const LiftingBlinks = ({ className }: Props): JSX.Element => {
  return (
    <div className={cn("w-full h-full", className)}>
      {BLINKS.map((blinkProps, index) => (
        <ButtonPrimaryBlink {...blinkProps} key={index} />
      ))}
    </div>
  );
};

export default LiftingBlinks;
