import { ReactNode, useEffect, useState } from "react";
import PageWithBackground from "~/components/layouts/PageWithBackground";
import { cn } from "~/utils";
import { ProgressBar } from "../progressBar";
import { isIOS } from "~/utils/userAgent";

const TIMEOUT_TO_UPDATE_PROGRESS = 400;
const COEFFICIENT_TO_UPDATE_PROGRESS = 0.25;

type Props = {
  isDone: boolean;
  className?: string;
  children?: ReactNode;
};

export const Loader = ({ isDone, className, children }: Props) => {
  const [progressValue, setProgressValue] = useState(0);

  useEffect(() => {
    if (isDone) {
      setProgressValue(1);
    }
  }, [isDone]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setProgressValue(value => {
        const rest = 1 - value;
        const randomAddition =
          Math.random() * rest * COEFFICIENT_TO_UPDATE_PROGRESS;

        return value + randomAddition;
      });
    }, TIMEOUT_TO_UPDATE_PROGRESS);

    return () => clearInterval(intervalId);
  }, []);

  const percent = Math.floor(progressValue * 100);

  return (
    <div
      className={cn(
        className,
        "h-full max-w-page-max m-auto bg-background-octonary"
      )}
    >
      <PageWithBackground
        className={cn("h-full w-full max-w-page-max flex flex-col")}
        topSpotClassName="left-1/2 -translate-x-1/2 top-[40%] -translate-y-1/2 h-[604px] w-[604px]"
        bottomSpotClassName="opacity-20"
      >
        <div
          className={cn("flex flex-col flex-1", {
            "pb-[88px]": isIOS()
          })}
        >
          {children}
        </div>
        <footer
          className={cn("fixed px-2 pb-4 bottom-0 w-full max-w-page-max", {
            "pb-6": isIOS()
          })}
        >
          <ProgressBar percent={percent} />
        </footer>
      </PageWithBackground>
    </div>
  );
};
