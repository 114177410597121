import { useTranslation } from "react-i18next";
import FriendsBonusDescriptionItem from "./FriendsBonusDescriptionItem";
import FriendsSection from "../FriendsSection";
import { FriendBonuses } from "../FriendsHeader/types";

type Props = {
  bonuses: FriendBonuses;
};

const FriendsBonusDescription = ({ bonuses }: Props): JSX.Element => {
  const { t } = useTranslation("friends");

  return (
    <FriendsSection>
      <FriendsBonusDescriptionItem
        amount={bonuses.regularAccountBonusForMe}
        description={t("bonus.forFriend")}
      />
      <FriendsBonusDescriptionItem
        amount={bonuses.premiumAccountBonusForMe}
        description={t("bonus.forPremiumFriend")}
      />
    </FriendsSection>
  );
};

export default FriendsBonusDescription;
