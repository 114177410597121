/* eslint-disable */
// @ts-nocheck
import * as Types from '../../../../../../declarations/graphql/types';

import { gql } from '@urql/core';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DTOBonusesForFriendsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type DTOBonusesForFriendsQuery = { __typename?: 'Query', rewardsOnRefereeLevelUp: Array<{ __typename?: 'RewardOnRefereeLevelUp', id: any, amount: number, level: { __typename?: 'RobotLevel', id: any, level: number, name: string } }> };


export const BonusesForFriendsQueryDocument = gql`
    query BonusesForFriendsQuery {
  rewardsOnRefereeLevelUp {
    id
    level {
      id
      level
      name
    }
    amount
  }
}
    `;

export function useBonusesForFriendsQuery(options?: Omit<Urql.UseQueryArgs<DTOBonusesForFriendsQueryVariables>, 'query'>) {
  return Urql.useQuery<DTOBonusesForFriendsQuery, DTOBonusesForFriendsQueryVariables>({ query: BonusesForFriendsQueryDocument, ...options });
};