/* eslint-disable */
// @ts-nocheck
import * as Types from '../../declarations/graphql/types';

import { gql } from '@urql/core';
export type DTORobotLevelFragment = { __typename?: 'RobotLevel', id: any, level: number, bodyImage: string, headImage: string, necessaryPower: number };

export const RobotLevelFragmentDoc = gql`
    fragment RobotLevelFragment on RobotLevel {
  id
  level
  bodyImage
  headImage
  necessaryPower
}
    `;