import { useTranslation } from "react-i18next";
import Button from "~/components/ui/button";
import WarningImage from "~/assets/images/Warning.png";
import PageWithBackground from "~/components/layouts/PageWithBackground";

type Props = {
  onTryAgain: () => void;
};

export const ErrorPlaceholder = ({ onTryAgain }: Props) => {
  const { t } = useTranslation("common");

  return (
    <PageWithBackground className="z-50 top-0 left-0 right-0 bottom-0 flex flex-col px-6 pt-2 pb-4 max-w-page-max m-auto fixed overflow-x-hidden h-full">
      <div className="flex-1 flex flex-col items-center justify-center w-full text-center mx-auto">
        <img
          src={WarningImage}
          height={144}
          className="h-[144px] w-auto object-cover"
        />
        <h2 className="mt-3 t-h4">
          {t("placeholder.somethingWentWrong.title")}
        </h2>
        <div className="my-2 t-p-quaternary max-w-[328px]">
          {t("placeholder.somethingWentWrong.description")}
        </div>
      </div>
      <Button size="lg" variant="accent" fullWidth onClick={onTryAgain}>
        {t("actions.tryAgain")}
      </Button>
    </PageWithBackground>
  );
};
