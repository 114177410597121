import { SpinePlayer as Player } from "@esotericsoftware/spine-player";
import {
  CreateImmortalSpinePlayerPromiseOptions,
  CreateImmortalSpinePlayerPromiseResult
} from "./types";

export const createImmortalSpinePlayer = ({
  atlasUrl,
  skeletonUrl,
  rawDataURIs,
  animationName
}: CreateImmortalSpinePlayerPromiseOptions) =>
  new Promise<CreateImmortalSpinePlayerPromiseResult>((resolve, reject) => {
    const wrapper = document.createElement("div");
    wrapper.className = "w-full h-full";

    const player = new Player(wrapper, {
      skeleton: skeletonUrl,
      atlas: atlasUrl,
      preserveDrawingBuffer: true,
      showControls: false,
      showLoading: false,
      alpha: true,
      rawDataURIs,
      success: () => {
        player.setAnimation(animationName, true);

        resolve({
          wrapper,
          player
        });
      },
      error: (_player, error) => {
        reject({ error });
      }
    });
  });
