/* eslint-disable */
// @ts-nocheck
import * as Types from '../../../../../../../declarations/graphql/types';

import { DTOTaskFragment } from './taskFragment.generated';
import { gql } from '@urql/core';
import { TaskFragmentDoc } from './taskFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DTOStartTaskMutationVariables = Types.Exact<{
  id: Types.Scalars['Uuid']['input'];
}>;


export type DTOStartTaskMutation = { __typename?: 'Mutation', startTaskProgress: (
    { __typename?: 'Task' }
    & DTOTaskFragment
  ) };


export const StartTaskMutationDocument = gql`
    mutation StartTaskMutation($id: Uuid!) {
  startTaskProgress(id: $id) {
    ...TaskFragment
  }
}
    ${TaskFragmentDoc}`;

export function useStartTaskMutation() {
  return Urql.useMutation<DTOStartTaskMutation, DTOStartTaskMutationVariables>(StartTaskMutationDocument);
};