/* eslint-disable */
// @ts-nocheck
import * as Types from '../../../../declarations/graphql/types';

import { DTOModelLevelFragment } from '../../modelLevelFragment.generated';
import { gql } from '@urql/core';
import { ModelLevelFragmentDoc } from '../../modelLevelFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DTOModelLevelsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type DTOModelLevelsQuery = { __typename?: 'Query', modelLevels: Array<(
    { __typename?: 'ModelLevel' }
    & DTOModelLevelFragment
  )> };


export const ModelLevelsQueryDocument = gql`
    query ModelLevelsQuery {
  modelLevels {
    ...ModelLevelFragment
  }
}
    ${ModelLevelFragmentDoc}`;

export function useModelLevelsQuery(options?: Omit<Urql.UseQueryArgs<DTOModelLevelsQueryVariables>, 'query'>) {
  return Urql.useQuery<DTOModelLevelsQuery, DTOModelLevelsQueryVariables>({ query: ModelLevelsQueryDocument, ...options });
};