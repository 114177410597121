/* eslint-disable */
// @ts-nocheck
import * as Types from '../../../../../declarations/graphql/types';

import { DTOUserFragment } from './walletFragment.generated';
import { gql } from '@urql/core';
import { UserFragmentDoc } from './walletFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DTOWalletQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type DTOWalletQuery = { __typename?: 'Query', wallet?: (
    { __typename?: 'Wallet' }
    & DTOUserFragment
  ) | null };


export const WalletDocument = gql`
    query Wallet {
  wallet {
    ...UserFragment
  }
}
    ${UserFragmentDoc}`;

export function useWalletQuery(options?: Omit<Urql.UseQueryArgs<DTOWalletQueryVariables>, 'query'>) {
  return Urql.useQuery<DTOWalletQuery, DTOWalletQueryVariables>({ query: WalletDocument, ...options });
};