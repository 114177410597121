import { UpdateResolver } from "@urql/exchange-graphcache";
import { gql } from "urql";
import { DTOClaimRewardMutation } from "~/components/farming/useFarming/graphql/claimRewardMutation.generated";
import { getMyId } from "~/providers/UrqlProvider/cacheExchange/updates/mutations/getMyId";

export const claimFarmingReward: UpdateResolver = (
  result: DTOClaimRewardMutation,
  _args,
  cache
) => {
  const fragment = gql`
    fragment _ on Me {
      id
      currentFarmingTask {
        ...CurrentFarmingTaskFragment
      }
    }
  `;

  const id = getMyId(cache);

  const finishedFarmingTask = result.claimFarmingReward;
  const serverTime = new Date(finishedFarmingTask.serverTime);
  const finishingAt = new Date(finishedFarmingTask.finishingAt);
  const newCurrentFarmingTask =
    finishingAt > serverTime ? finishedFarmingTask : null;

  cache.writeFragment(fragment, {
    id,
    currentFarmingTask: newCurrentFarmingTask
  });
};
