/* eslint-disable */
// @ts-nocheck
import * as Types from '../../../../../declarations/graphql/types';

import { DTOSpinRewardFragment } from './spinRewardFragment.generated';
import { gql } from '@urql/core';
import { SpinRewardFragmentDoc } from './spinRewardFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DTORouletteQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type DTORouletteQuery = { __typename?: 'Query', freeSpinSectors: { __typename?: 'FreeSpinSectors', hash: string, values: Array<(
      { __typename?: 'FreeSpinSector' }
      & DTOSpinRewardFragment
    )> } };


export const RouletteQueryDocument = gql`
    query RouletteQuery {
  freeSpinSectors {
    hash
    values {
      ...SpinRewardFragment
    }
  }
}
    ${SpinRewardFragmentDoc}`;

export function useRouletteQuery(options?: Omit<Urql.UseQueryArgs<DTORouletteQueryVariables>, 'query'>) {
  return Urql.useQuery<DTORouletteQuery, DTORouletteQueryVariables>({ query: RouletteQueryDocument, ...options });
};