import { POWER_CATEGORY_QUERY_DEFAULT } from "~/components/ai/power/constants";
import { TaskFilter } from "~/components/rewards/Rewards/Tasks/types";
import { TASK_GROUP_QUERY_PARAMETER } from "~/components/rewards/Rewards/Tasks/useTasks/constants";

export const WITH_NAVIGATION_LAYOUT = "/_withNavigation";

export enum RoutePath {
  farm = "/",
  ai = "/ai",
  boost = "/boost",
  power = "/power",
  friends = "/friends",
  rewards = "/rewards",
  dailyRewards = `${RoutePath.rewards}?${TASK_GROUP_QUERY_PARAMETER}=${TaskFilter.daily}`,
  easyRewards = `${RoutePath.rewards}?${TASK_GROUP_QUERY_PARAMETER}=${TaskFilter.easy}`,
  leaders = "/leaders",
  referral = "/referral",
  roadmap = "/roadmap",
  dailyBonus = "/dailyBonus",
  spins = "/spins",
  premiumShop = "/premiumShop",
  airdrop = "/airdrop"
}

export const POWER_ROUTE_WITH_DEFAULT_CATEGORY = {
  to: RoutePath.power,
  search: () => POWER_CATEGORY_QUERY_DEFAULT
};
