/* eslint-disable */
// @ts-nocheck
import * as Types from '../../../../declarations/graphql/types';

import { gql } from '@urql/core';
export type DTOCurrentModelFragment = { __typename?: 'ModelLevel', id: any, number: number, rate: number };

export const CurrentModelFragmentDoc = gql`
    fragment CurrentModelFragment on ModelLevel {
  id
  number
  rate
}
    `;