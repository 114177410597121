import { FriendBonuses } from "~/components/friends/FriendsHeader/types";
import { useBonusesForFriendsQuery } from "./graphql/bonusesForFriendsQuery.generated";
import { FriendsLevelBonusByLevel } from "./types";

type Result = {
  bonuses: FriendsLevelBonusByLevel[];
  maxBonus: FriendsLevelBonusByLevel | null;
};

export const useBonusesForFriendsByLevel = (
  firstLevelBonuses: FriendBonuses
): Result => {
  const [{ data: bonusesForFriends }] = useBonusesForFriendsQuery();
  const bonuses: FriendsLevelBonusByLevel[] =
    bonusesForFriends?.rewardsOnRefereeLevelUp
      ?.map<FriendsLevelBonusByLevel>(bonus => ({
        level: bonus.level.level,
        name: bonus.level.name,
        count:
          bonus.level.level === 1
            ? firstLevelBonuses.regularAccountBonusForMe
            : bonus.amount,
        alternativeCount:
          bonus.level.level === 1
            ? firstLevelBonuses.premiumAccountBonusForMe
            : null
      }))
      .sort((a, b) => a.level - b.level) ?? [];

  const maxBonus = bonuses[bonuses.length - 1] ?? null;

  return {
    bonuses,
    maxBonus
  };
};
