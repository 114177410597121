import { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "~/components/ui/button";
import FriendsSection from "../FriendsSection";
import Share from "~/assets/icons/Share.svg?react";
import Copy from "~/assets/icons/Copy.svg?react";
import copyToClipboard from "~/utils/clipboard/copyToClipboard";
import { toast } from "sonner";
import useReferralLink from "./useReferralLink";
import { shareURL } from "@telegram-apps/sdk-react";

const COPIED_SHOWN_TIMEOUT = 2000;

const FriendsActions = (): JSX.Element => {
  const { t } = useTranslation("friends");
  const [copied, setCopied] = useState(false);
  const referralLink = useReferralLink();

  const handleCopyToClipboard = async (): Promise<void> => {
    if (!referralLink) {
      return;
    }

    await copyToClipboard(referralLink);

    setCopied(true);
    toast.success(t("actions.linkCopied"));

    setTimeout(() => {
      setCopied(false);
    }, COPIED_SHOWN_TIMEOUT);
  };

  const handleShareLink = async (): Promise<void> => {
    if (!referralLink) {
      return;
    }

    shareURL(
      referralLink,
      t("refMeta", {
        ns: "common"
      })
    );
  };

  return (
    <FriendsSection
      className="[@media(width<375px)]:flex-col [@media(width<375px)]:gap-4 [@media(width<375px)]:h-auto"
      dividerClassName="[@media(width<375px)]:hidden"
    >
      <Button
        className="overflow-hidden [&>button]:overflow-hidden"
        buttonClassName="px-5"
        variant="accent"
        fullWidth
        onClick={handleShareLink}
      >
        <Share className="w-5 h-5 mr-2 shrink-0" />
        <div className="shrink-1 overflow-hidden text-ellipsis">
          {t("actions.invite")}
        </div>
      </Button>
      <Button
        variant="accent"
        className="overflow-hidden [&>button]:overflow-hidden"
        buttonClassName="px-5"
        fullWidth
        onClick={copied ? undefined : handleCopyToClipboard}
      >
        <Copy className="w-5 h-5 mr-2 shrink-0" />
        <div className="shrink-1 overflow-hidden text-ellipsis">
          {t(copied ? "actions.copied" : "actions.copyLink")}
        </div>
      </Button>
    </FriendsSection>
  );
};

export default FriendsActions;
