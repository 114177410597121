/* eslint-disable */
// @ts-nocheck
import * as Types from '../../declarations/graphql/types';

import { gql } from '@urql/core';
export type DTOModelLevelFragment = { __typename?: 'ModelLevel', id: any, name: string, number: number, rate: number, duration: number, price: number, minPower: number, image: string };

export const ModelLevelFragmentDoc = gql`
    fragment ModelLevelFragment on ModelLevel {
  id
  name
  number
  rate
  duration
  price
  rate
  minPower
  image
}
    `;