import { Referral } from "../declarations";
import CoinIcon from "~/assets/icons/Coin.png";
import { formatTokens } from "~/components/balance/Tokens/utils";

const FriendsReferral = ({
  name,
  robotLevel,
  reward
}: Referral): JSX.Element => {
  return (
    <div className="rounded-24 py-3 pl-3 pr-4 mb-1 bg-white/4 flex items-center gap-[10px] backdrop-blur-md">
      <img
        src={robotLevel?.headImage}
        className="flex-shrink-0 w-10 h-10 rounded-full bg-white/8 p-0.5"
      />
      <span className="t-h6 text-sm overflow-hidden text-ellipsis w-full flex-shrink">
        {name}
      </span>
      <img src={CoinIcon} className="ml-auto mr-2 w-6 h-6" />
      <span className="t-h6 leading-[22px] font-regular">
        +{formatTokens(reward)}
      </span>
    </div>
  );
};

export default FriendsReferral;
